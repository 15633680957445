import BGPH1 from '../../../assets/images/backgrounds/Services.png'
import BGS1 from '../../../assets/images/services/service-s-1-1.png'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import SERVICE1 from '../../../assets/images/services/AlliedHealthcare.png'


const ServiceDetails = () => {
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Quisque quis urna consequat, scelerisque",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Mauris a ipsum id libero sodales dapibus",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat.There are many variations of passages of available but the majority have suffered alteration that some form by injected randomised words which don’t look even as slightly believable now. Phasellus mollis ac augue sed varius. "
        },
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        }
    ]
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Courses</span></li> */}
                    </ul>
                    <h2>Allied Health Services
                    </h2>
                </div>
            </section>
            <section className="service-details pt-120 pb-120">
                <div className="container">
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__item service-sidebar__item--menu">
                                    <ul className="service-sidebar__menu">
                                        <li ><Link to="/in-home-services">In Home Services</Link></li>
                                        <li><Link to="/community-participation">Community Participation</Link></li>
                                        <li><Link to="/aged-care">Aged
                                            Care </Link></li>
                                        <li className="active"><Link to="/allied-health-services">Allied Health
                                            Services</Link></li>

                                    </ul>
                                </div>
                                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                                    <div className="service-sidebar__bg" style={{ backgroundImage: `url(${BGS1})` }}>
                                    </div>
                                    <div className="service-sidebar__contact">
                                        <div className="service-sidebar__icon">
                                            <i className="icon-phone-ringing"></i>
                                        </div>
                                        <h3 className="service-sidebar__title">Quick loan
                                            proccess</h3>
                                        <hr className="service-sidebar__separator" />
                                        <p className="service-sidebar__tagline">Talk to an expert</p>
                                        <a href='tel:0413599838' className="service-sidebar__phone">0413 599 838</a>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details__image">
                                <img src={SERVICE1} alt="" />
                                {/* <div className="service-details__icon">
                                    <i className="icon-diamond"></i>
                                </div> */}
                            </div>
                            <div className="service-details__content">
                                <h3 className="service-details__title">Allied Health Services - Comprehensive Allied Health Support</h3>
                                <p>Our Allied Health Services, including Speech Therapy and Physiotherapy, are designed to enhance overall well-being and address specific health needs. Discover the benefits of our specialized allied health programs.</p>
                                {/* <p>List of documents typically required for the loan. These documents may vary depending on the lender, loan type, and individual circumstances. It's always best to consult with us for the most accurate and up-to-date information regarding the required documentation.</p> */}


                                <div className="block-title text-left">
                                    {/* <p className="block-title__tagline">About Company</p> */}
                                    <h2 className="block-title__title pt-20" style={{ fontSize: "35px" }} >Speech Therapy: Enhancing Communication Skills and Swallowing Abilities</h2>

                                </div>
                                <p className="about-two__text">At Glorious Support Services, our Speech Therapy services are crafted to empower individuals to communicate effectively and enhance their swallowing abilities. Our experienced and qualified speech therapists work closely with clients to address a range of communication challenges and promote improved swallowing function.</p>


                                <div className="block-title text-left">
                                    {/* <p className="block-title__tagline">About Company</p> */}
                                    <h2 className="block-title__title pt-20" style={{ fontSize: "30px" }} >Communication Skills Enhancement</h2>

                                </div>
                                <p className="about-two__text">Our speech therapy sessions focus on developing and enhancing communication skills tailored to everyone’s unique needs. Whether it's articulation, language development, or social communication, our therapists employ evidence-based techniques to foster meaningful and effective communication. We create a supportive and engaging environment where clients can build confidence in expressing themselves.</p>


                                <div className="block-title text-left">
                                    {/* <p className="block-title__tagline">About Company</p> */}
                                    <h2 className="block-title__title pt-20" style={{ fontSize: "30px" }} >Key Areas of Focus</h2>

                                </div>

                                <div className="col-md-12 pt-10">


                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Articulation and Pronunciation

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Language Development
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Social Communication


                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Voice Modulation

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Speech Therapy: Enhancing Communication Skills and Swallowing Abilities

                                            </span>
                                        </li>

                                    </ul>
                                </div>

                                <div className="block-title text-left">
                                    {/* <p className="block-title__tagline">About Company</p> */}
                                    <h2 className="block-title__title pt-40" style={{ fontSize: "35px" }} >Physiotherapy: Promoting Mobility, Strength, and Overall Physical Well-being</h2>

                                </div>
                                <p className="about-two__text">Glorious Support Services offers comprehensive Physiotherapy services designed to promote mobility, enhance strength, and improve the overall physical well-being of our clients. Our dedicated physiotherapists collaborate with individuals to create personalized plans that address specific physical challenges and optimize functional independence.</p>


                                <div className="block-title text-left">
                                    {/* <p className="block-title__tagline">About Company</p> */}
                                    <h2 className="block-title__title pt-20" style={{ fontSize: "30px" }} >Mobility Enhancement</h2>

                                </div>
                                <p className="about-two__text">Our physiotherapy sessions focus on improving mobility and functional movement. Whether you're recovering from an injury, managing a chronic condition, or seeking to enhance overall mobility, our physiotherapists employ evidence-based techniques and exercises tailored to your unique needs.</p>

                                <div className="block-title text-left">
                                    {/* <p className="block-title__tagline">About Company</p> */}
                                    <h2 className="block-title__title pt-20" style={{ fontSize: "30px" }} >Key Areas of Focus</h2>

                                </div>

                                <div className="col-md-12 pt-10">


                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Joint Mobility


                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Gait Training
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Balance Improvement


                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Transfer Skills

                                            </span>
                                        </li>
                                      

                                    </ul>
                                </div>

                                {/* <p className="about-two__text pt-20">Welcome to Glorious Support Services – where empowerment, compassion, and customized support come together to enable a brighter and more fulfilling future for individuals with disabilities.</p> */}


                                {/* <div className="col-md-12 pt-20">


                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Science

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Business                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Engineering

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Arts and Humanities
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Health Sciences
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Information Technology
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Purchase contract or signed letter of offer
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Social Sciences
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Design and Creative Arts
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Economics
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Environmental Studies
                                            </span>
                                        </li>
                                    </ul>
                                </div> */}



                                {/* <div className="row row-gutter-y-30">
                                    <div className="col-md-6">
                                        <img src="assets/images/services/service-d-1-2.png" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <h3 className="service-details__subtitle">Service benefits</h3>
                                        <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                            esse cillum.</p>
                                        <ul className="list-unstyled ml-0 service-details__list">
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Refresing to get such a personal touch.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Velit esse cillum eu fugiat pariatur.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
                                            {faqs.map((item, index) => (
                                                <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                                    <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                        <h4>{item.question}</h4>
                                                        <span className="accrodion-icon"></span>
                                                    </div>
                                                    {index === clicked && <div className="accrodion-content">
                                                        <div className="inner">
                                                            <p>{item.answer}</p>
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <Link to="/servicedetails" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}
        </>
    )
}

export default ServiceDetails