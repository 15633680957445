import BGPH1 from '../../../assets/images/backgrounds/Services.png'
import BGS1 from '../../../assets/images/services/service-s-1-1.png'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import SERVICE1 from '../../../assets/images/services/CommunityParticipation.png'

const ServiceDetails = () => {
    const [clicked, setClicked] = useState(0);
    const faqs = [
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Quisque quis urna consequat, scelerisque",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Mauris a ipsum id libero sodales dapibus",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat.There are many variations of passages of available but the majority have suffered alteration that some form by injected randomised words which don’t look even as slightly believable now. Phasellus mollis ac augue sed varius. "
        },
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        }
    ]
    return (
        <>
            <div className="stricky-header stricked-menu main-menu">
                <div className="sticky-header__content"></div>
            </div>
            <section className="page-header">
                <div className="page-header__bg" style={{ backgroundImage: `url(${BGPH1})` }}></div>
                <div className="container">
                    <ul className="thm-breadcrumb list-unstyled">
                        {/* <li><Link to="/">Home</Link></li>
                        <li><span>Courses</span></li> */}
                    </ul>
                    <h2>Community Participant
                    </h2>
                </div>
            </section>
            <section className="service-details pt-120 pb-120">
                <div className="container">
                    <div className="row row-gutter-y-30">
                        <div className="col-lg-4">
                            <div className="service-sidebar">
                                <div className="service-sidebar__item service-sidebar__item--menu">
                                    <ul className="service-sidebar__menu">
                                        <li ><Link to="/in-home-services">In Home Services</Link></li>
                                        <li className="active"><Link to="/community-participation">Community Participation</Link></li>
                                        <li><Link to="/aged-care">Aged
                                            Care </Link></li>
                                        <li><Link to="/allied-health-services">Allied Health
                                            Services</Link></li>

                                    </ul>
                                </div>
                                {/* <div className="service-sidebar__item service-sidebar__item--contact">
                                    <div className="service-sidebar__bg" style={{ backgroundImage: `url(${BGS1})` }}>
                                    </div>
                                    <div className="service-sidebar__contact">
                                        <div className="service-sidebar__icon">
                                            <i className="icon-phone-ringing"></i>
                                        </div>
                                        <h3 className="service-sidebar__title">Quick loan
                                            proccess</h3>
                                        <hr className="service-sidebar__separator" />
                                        <p className="service-sidebar__tagline">Talk to an expert</p>
                                        <a href='tel:0413599838' className="service-sidebar__phone">0413 599 838</a>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details__image">
                                <img src={SERVICE1} alt="" />
                                {/* <div className="service-details__icon">
                                    <i className="icon-diamond"></i>
                                </div> */}
                            </div>
                            <div className="service-details__content">
                                <h3 className="service-details__title">Community Participant - Connecting Communities, Enhancing Lives</h3>
                                <p>Our Community Access services are crafted to foster social connections, provide engaging activities, and support daily needs. Discover how we can help you or your loved ones stay connected and active within the community.</p>
                                {/* <p>List of documents typically required for the loan. These documents may vary depending on the lender, loan type, and individual circumstances. It's always best to consult with us for the most accurate and up-to-date information regarding the required documentation.</p> */}

                                <ul className="list-unstyled ml-0 about-two__list pb-40">
                                    <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                        {/* <i className="fa fa-arrow-circle-right"></i> */}
                                        <b>
                                            Social Connections  :
                                        </b>
                                        <br />
                                        Building Meaningful Relationships Our Community Access services focus on fostering social connections. We believe in the power of meaningful relationships and provide opportunities for socializing, ensuring that you remain connected with others in your community.                                    </li>

                                    <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                        {/* <i className="fa fa-arrow-circle-right"></i> */}
                                        <b>
                                            Sports & Recreation  :
                                        </b>
                                        <br />
                                        Staying Active and Enjoying Recreational Activities Stay active and engaged with our Sports & Recreation services. We offer a variety of activities tailored to your interests, promoting physical fitness and overall well-being.                                    </li>

                                    <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                        {/* <i className="fa fa-arrow-circle-right"></i> */}
                                        <b>
                                            Transport Assistance  :
                                        </b>
                                        <br />
                                        Reliable Support for Mobility Needs Maintain your mobility with our reliable Transport Assistance services. Whether it's for medical appointments, social outings, or daily errands, our team ensures you get where you need to go comfortably and safely.                                    </li>

                                    <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                        {/* <i className="fa fa-arrow-circle-right"></i> */}
                                        <b>
                                            Shopping & Administrative Assistance  :
                                        </b>
                                        <br />
                                        Hassle-free Support for Daily Tasks Experience hassle-free support for daily tasks such as shopping and administrative responsibilities. Our dedicated team is here to help, ensuring that you have the support you need to manage your day-to-day affairs effortlessly.
                                    </li>

                                    <li className='mt-4 ' style={{ marginLeft: "-30px" }}>
                                        {/* <i className="fa fa-arrow-circle-right"></i> */}
                                        <b>
                                            Daily Activities Support :
                                        </b>
                                        <br />
                                        Assistance for Day-to-Day Tasks Our Daily Activities Support services are designed to make your daily routine more manageable. From household chores to organizing activities, our team is here to provide the assistance you need.                                    </li>



                                </ul>

                                {/* <div className="col-md-12 pt-20">


                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Science

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Business                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Engineering

                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Arts and Humanities
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Health Sciences
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Information Technology
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Purchase contract or signed letter of offer
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Social Sciences
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Design and Creative Arts
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Economics
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span>
                                                Environmental Studies
                                            </span>
                                        </li>
                                    </ul>
                                </div> */}



                                {/* <div className="row row-gutter-y-30">
                                    <div className="col-md-6">
                                        <img src="assets/images/services/service-d-1-2.png" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <h3 className="service-details__subtitle">Service benefits</h3>
                                        <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                            esse cillum.</p>
                                        <ul className="list-unstyled ml-0 service-details__list">
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Refresing to get such a personal touch.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Velit esse cillum eu fugiat pariatur.
                                            </span>
                                                </li>
                                            <li>
                                                <i className="fa fa-check-circle"></i>
                                                <span>
                                                Duis aute irure dolor in in voluptate.
                                            </span>
                                                </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
                                            {faqs.map((item, index) => (
                                                <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                                    <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                        <h4>{item.question}</h4>
                                                        <span className="accrodion-icon"></span>
                                                    </div>
                                                    {index === clicked && <div className="accrodion-content">
                                                        <div className="inner">
                                                            <p>{item.answer}</p>
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Link to="/servicedetails" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}
        </>
    )
}

export default ServiceDetails